import Vue from 'vue';
import VueMeta from 'vue-meta';
import { sentryInit } from '@/helpers/SentryInit';
import AppHg from './AppHg.vue';
import domusFilters from '@smg-real-estate/domus/src/filters';
import { CHUNK_LOAD_ERROR_MESSAGE_REGEX } from '@/helpers/SentryInit';
import store from './store';
import { Auth0Plugin } from '@/auth/auth0Plugin';
import { createI18n } from '@/i18n-setup-hg';
import { createRouter } from './router';
import routes from '@/router/hg/routes';
import guards from '@/router/hg/guards';
import { persistTheSource } from './helpers/localStorageHelper';
const i18n = createI18n();

const VueInit = () => {
  Vue.config.productionTip = false;
  Vue.use(domusFilters);
  Vue.use(VueMeta);
  Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH_AUDIENCE,
    cookieDomain: process.env.VUE_APP_AUTH_COOKIE_DOMAIN,
    redirectUri: `${window.location.origin}/login-callback`,
  });
  persistTheSource();

  new Vue({
    router: createRouter(routes, guards, (locale: string) => {
      i18n.locale = locale;
    }),
    store,
    i18n,
    render: (h) => h(AppHg),
    mounted() {
      this.$router.onError((error) => {
        if (CHUNK_LOAD_ERROR_MESSAGE_REGEX.test(error?.message)) {
          this.$router.replace(`/${i18n.locale}/not-found`);
        }
      });
    },
  }).$mount('#app');
};

sentryInit();
VueInit();
