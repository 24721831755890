import { StorageHelper } from '@smg-real-estate/hg-search-libs';

export enum Key {
  CLIENT_LOGIN_PROVIDER = 'clientLoginProvider',
  USER_TYPE = 'userType',
  USER_PROFILE_ID = 'userProfileId',
}

export enum StorageNamespace {
  ACCOUNT_PAGE = 'hg-account-page',
  USER_PROFILE = 'user-profile',
}
export enum UserType {
  SEEKER = 'seeker',
  LISTER = 'lister',
}

export const trackingStorage: StorageHelper = new StorageHelper('tracking');
export const userTypeStorage: StorageHelper = new StorageHelper(StorageNamespace.ACCOUNT_PAGE);

export const clearUserProfileStorage = (): void => {
  const userProfileStorage: StorageHelper = new StorageHelper(StorageNamespace.USER_PROFILE);
  userProfileStorage.removeLocalStorageItem(Key.USER_PROFILE_ID);
};
