import { FeaturesState } from '../types/features-state';

const state: FeaturesState = {
  enabledFeatures: undefined,
};

const getters = {
  enabledFeatures: ({ enabledFeatures }: FeaturesState): string[] => enabledFeatures,
};

const mutations = {
  setEnabledFeatures(state: FeaturesState, value: string[]): void {
    state.enabledFeatures = value;
  },
};

const FeaturesModule = { namespaced: true, state, getters, mutations };
export default FeaturesModule;
