import { HgSupportedLanguage } from '@smg-real-estate/domus';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DEFAULT_LANGUAGE } from './i18n';

Vue.use(VueI18n);

export enum LocaleFileSuffix {
  HG = 'hg',
  IS24 = 'is24',
}

let localeSuffix = LocaleFileSuffix.HG;

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  silentFallbackWarn: true,
  missing: (currentLocale, key) => console.warn(`Missing translations for "${key}" in "${currentLocale}"`),
  messages: {},
});

function setLocale(locale: HgSupportedLanguage): void {
  i18n.locale = locale;
}

export function initI18n(translations: any = {}, fileSuffix = LocaleFileSuffix.HG): VueI18n {
  // update suffix (e.g. `i18n/en-{suffix}.ts`)
  localeSuffix = fileSuffix;
  // add default messages
  i18n.setLocaleMessage(DEFAULT_LANGUAGE, { ...translations });
  return i18n;
}

export async function safeLoadTranslations(locale: HgSupportedLanguage): Promise<void> {
  try {
    await loadTranslations(locale);
  } catch (e) {
    console.error('Could not load locales', e);
  }
}

export async function loadTranslations(locale: HgSupportedLanguage): Promise<void> {
  const localeLoaded = await import(
    /* webpackChunkName: "lang-[request]" */
    `@/i18n/${locale}-${localeSuffix}.ts`
  );
  i18n.setLocaleMessage(locale, { ...localeLoaded.default });
}

export async function setLocaleBeforeRouteChange(locale: HgSupportedLanguage): Promise<void> {
  if (!(locale in i18n.messages)) {
    await safeLoadTranslations(locale);
  }
  setLocale(locale);
}
