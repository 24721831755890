export interface User {
  id: string;
  personId: string;
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  picture?: string;
  groups: GroupType[];
  customerType?: string;
  identities?: string;
  propertiesCount?: number;
}

export enum GroupType {
  AGENCY = 'agency',
  CCC = 'ccc',
  PRIVATE = 'private',
}
