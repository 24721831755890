export const parseHrefByEnvironment = function (href: string, isHgPlatform?: boolean) {
  const environment = process.env.VUE_APP_ENVIRONMENT || 'prod';
  if (['test', 'dev'].includes(environment)) {
    if (isHgPlatform) {
      return href.replace('www', 'test');
    } else if (isHgPlatform === false) {
      return href.replace('www', 'web-int-00');
    }
  }
  return href;
};

export const assert = (condition, message = 'Assertion failed') => {
  if (!condition) {
    throw new Error(message);
  }
};

export const convertCardMask = (cardMask: string): string => {
  return `**** ${cardMask.substring(cardMask.length - 4)}`;
};
