import { getAuth0Client } from '@/auth/auth0Client';

export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const authClient = getAuth0Client();
    if (!(await authClient.isAuthenticated())) {
      return undefined;
    }
    return await authClient.getTokenSilently();
  } catch (error) {
    return undefined;
  }
};

export async function getUserId(): Promise<string | undefined> {
  try {
    const authClient = getAuth0Client();
    const claims = await authClient.getIdTokenClaims();
    return claims?.['cognito:username'] || '';
  } catch (error) {
    console.error('Error in getUserId:', error);
    return undefined;
  }
}
