var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c('HgLayout',{attrs:{"baseUrl":_vm.baseUrl,"currentLanguage":_vm.currentLocale,"currentRoute":_vm.currentRoute,"getLanguageToLink":_vm.getLanguageSwitchLink,"hideMainNavigation":_vm.isMainNavigationHidden},on:{"trackFooterClick":_vm.trackClickHandler,"trackHeaderClick":_vm.trackHeaderClickHandler},scopedSlots:_vm._u([{key:"headerMetaAction",fn:function(){return [_c('HgMainNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.newListingMenuItem'),"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl,"bordered":true},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_INSERT_AD,
            eventLabel: _vm.EventLabel.TAB_NAVIGATION,
            contentLanguage: _vm.$i18n.locale,
          })}}})]},proxy:true},{key:"headerMetaNavigation",fn:function(){return [(!_vm.isMainNavigationHidden)?_c('HgMainNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.favoriteMenuItem'),"icon":_vm.HeartOutlineIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_FAVOURITES,
            eventLabel: _vm.EventLabel.TAB_NAVIGATION,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),_c('HgMainNavItem',{attrs:{"baseUrl":_vm.baseUrl,"menuItem":_vm.loggedInMenuItem,"accountsOrigin":_vm.accountsDomainOrigin,"icon":_vm.PersonOutlineIcon,"currentRoute":_vm.currentRoute,"dropdownOrientation":"right"}})]},proxy:true},{key:"sideMenuMetaNavigation",fn:function(){return [(!_vm.isMainNavigationHidden)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.favoriteMenuItem'),"icon":_vm.HeartOutlineIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_FAVOURITES,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),(_vm.isPrivateUser)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.overviewMenuItem'),"icon":_vm.PersonOutlineIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.accountsDomainOrigin},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_ACCOUNT_OVERVIEW,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),(_vm.isPrivateUser)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.settingsMenuItem'),"icon":_vm.SettingsIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.accountsDomainOrigin},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_ACCOUNT_SETTINGS,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),(_vm.isPrivateUser)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.myAdsMenuItem'),"icon":_vm.AppointmentIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_MY_ADS,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),(_vm.isPrivateUser)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.searchAlertMenuItem'),"icon":_vm.BellIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_SEARCH_ALERT,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),(_vm.isPrivateUser)?_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.messagesMenuItem'),"icon":_vm.MessengerIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.baseUrl},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_MESSENGER,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}}):_vm._e(),_c('HgSideMenuNavItem',{attrs:{"menuItem":_vm.$t('$hg.nav.harmonize.signOutMenuItem'),"icon":_vm.SignOutIcon,"currentRoute":_vm.currentRoute,"baseUrl":_vm.accountsDomainOrigin},nativeOn:{"click":function($event){return _vm.trackHeaderClickHandler({
            eventAction: _vm.InteractionGoal.OPEN_LOGOUT,
            eventLabel: _vm.EventLabel.SIDEBAR,
            contentLanguage: _vm.$i18n.locale,
          })}}})]},proxy:true}])},[_c('div',{staticClass:"layout-container"},[_c('router-view')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }