import { RouteConfig } from 'vue-router';
import { RouteConfigSingleView } from 'vue-router/types/router';

const generateRoute = ({
  path,
  name,
  component,
  meta,
  redirect,
  alias,
  beforeEnter,
}: RouteConfigSingleView): RouteConfig => {
  return {
    path,
    name,
    component,
    alias,
    redirect,
    beforeEnter,
    meta: {
      ...meta,
    },
  };
};

export default generateRoute;
