<template>
  <div>
    <HgPageNotFound description=" " :displayBuyCta="false" :displayRentCta="false" :theme="getCurrentPlatformTheme()">
      <HgButton tag="a" :href="backToSignInPath">
        {{ $t('pageNotFound.cta') }}
      </HgButton>
    </HgPageNotFound>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { HgPageNotFound, HgButton } from '@smg-real-estate/domus';
import { getCurrentPlatformTheme } from '@/helpers/PlatformDetection';

export default defineComponent({
  name: 'PageNotFound',
  components: {
    HgPageNotFound,
    HgButton,
  },
  methods: {
    getCurrentPlatformTheme,
  },
  computed: {
    backToSignInPath(): string {
      return `/${this.$i18n.locale}/sign-in`;
    },
  },
});
</script>
