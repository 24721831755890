import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

export function createRouter(routes: RouteConfig[], guards: Function, localeWatcher?: (locale: string) => any) {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  const { beforeEach } = guards(localeWatcher);
  router.beforeEach(beforeEach);

  return router;
}
