import { Auth0Client, Auth0ClientOptions, createAuth0Client } from '@auth0/auth0-spa-js';
import { assert } from '@/helpers/utils';

let client: Auth0Client;

export const initAuth0Client = async (options: Auth0ClientOptions): Promise<Auth0Client> => {
  client = await createAuth0Client(options);
  return client;
};

export const getAuth0Client = (): Auth0Client => {
  assert(client instanceof Auth0Client, 'Auth0 client not initialized');
  return client;
};
