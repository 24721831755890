<template>
  <component v-if="layoutComponent" :is="layoutComponent"><router-view /></component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MetaInfo } from 'vue-meta';
import { HgSupportedLanguage } from '@smg-real-estate/domus';

import HgLayoutDefault from '@/layouts/HgLayoutDefault.vue';

export default defineComponent({
  name: 'AppHg',
  computed: {
    layoutComponent(): any {
      if (!this.$route.name) {
        return null;
      }
      return HgLayoutDefault;
    },
  },
  metaInfo(): MetaInfo {
    const lang = this.$i18n.locale === HgSupportedLanguage.EN ? this.$i18n.locale : `${this.$i18n.locale}-CH`;
    return {
      htmlAttrs: {
        lang,
      },
    };
  },
});
</script>

<style lang="scss">
@import '~@/assets/global-hg';
</style>
