export enum PaymentType {
  FREE_TRIAL = 'free',
  CREDIT_CARD = 'cc',
  MANUAL_PAYMENT = 'manual',
}
export enum SubscriptionState {
  CREATED = 'created',
  FAILED = 'failed',
  CANCELED = 'canceled',
  REFUND = 'refund',
  EXPIRED = 'expired',
}
export interface SubscriptionModel {
  userId: string;
  subscriptionState: string;
  createdAt: string;
  expiredAt: string | undefined;
  trialExpiredAt: string;
  updatedAt: string;
  paymentType: string;
  accountEmailAddress: string;
  autoRenewalEnabled?: boolean;
  metadata: SubscriptionMetadataModel | undefined;
  transactionData?: TransactionData;
}

export interface SubscriptionMetadataModel {
  hadTrial: boolean;
  transactionData?: TransactionData;
}

export interface AuthenticationModel {
  isTenantPlusSubscriber: boolean;
  subscriptionInfo: SubscriptionModel | undefined;
}

export interface TrackingData {
  eventAction: string;
  eventLabel: string;
}

export interface HeaderTrackingData {
  eventAction: string;
  eventLabel: string;
  contentLanguage: string;
}

export interface TransactionData {
  transactionId: string;
  status: string;
  paymentMethod: string;
  card?: { masked?: string; expiryYear?: string; expiryMonth?: string };
}

export enum DataTransPaymentMethod {
  MASTER_CARD = 'ECA',
  VISA = 'VIS',
  TWINT = 'TWI',
}
