import Vue from 'vue';
import Vuex from 'vuex';

import { ExperimentVuexModule } from '@smg-real-estate/vue-experiments';
import featuresModule from './modules/features';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    experiments: ExperimentVuexModule,
    features: featuresModule,
  },
});
