import type { Event, ErrorEvent, EventHint } from '@sentry/types';

function hasBlockingTypeErrorByContext(event: ErrorEvent): boolean {
  const blockingTypeErrorByContext = [
    // Example: https://homegate-ag.sentry.io/issues/4938079038/?alert_rule_id=14707769&alert_type=issue&environment=prod&notification_uuid=c27b4992-9bbc-4d52-9cb3-a6136549c7aa&project=1396483
    {
      exception: 'the given value is not a Promise',
      browser: ['Chrome/66'],
      os: ['Windows NT'],
    },
  ];

  return blockingTypeErrorByContext.some((blockingItem) => {
    const matchException = event.exception?.values?.some((exception) => {
      return exception.mechanism?.type === 'onunhandledrejection' && exception.value?.includes(blockingItem.exception);
    });

    if (!matchException) {
      return false;
    }

    const userAgent = event.request?.headers?.['User-Agent'];

    if (userAgent) {
      const matchBrowser = blockingItem.browser.some((browser) => userAgent.includes(browser));
      const matchOs = blockingItem.browser.some((os) => userAgent.includes(os));

      return matchBrowser && matchOs;
    }

    return false;
  });
}

function hasBlockingUnhandledRejections(event: ErrorEvent): boolean {
  const exceptionMessage = 'Non-Error promise rejection captured';
  const exceptionDefaultData = ['btloader.com', 'api.marketing.swissmarketplace.group'];

  const blockingUnhandledRejections = [
    // Example: https://homegate-ag.sentry.io/issues/1417233125/?alert_rule_id=14519848&alert_type=issue&environment=prod&notification_uuid=c0f78e14-d9ef-45f5-8a2f-23933df501af&project=1396483
    {
      exception: exceptionMessage,
      category: 'fetch',
      data: [
        { url: exceptionDefaultData[0], level: 'error' },
        { url: exceptionDefaultData[1], level: 'info' },
      ],
    },
    {
      exception: exceptionMessage,
      category: 'fetch',
      data: [
        { url: exceptionDefaultData[0], level: 'info' },
        { url: exceptionDefaultData[1], level: 'error' },
      ],
    },
    {
      exception: exceptionMessage,
      category: 'fetch',
      data: [
        { url: exceptionDefaultData[0], level: 'info' },
        { url: exceptionDefaultData[1], level: 'info' },
        { url: 'hbopenbid.pubmatic.com', level: 'error' },
      ],
    },
    // Example: https://homegate-ag.sentry.io/issues/1417233125/events/34c3bff7fec64016b3acc271c50db84e/?project=1396483
    {
      exception: exceptionMessage,
      category: 'promise',
      data: [{ url: exceptionDefaultData[0], level: 'error' }],
    },
    {
      exception: exceptionMessage,
      category: 'promise',
      data: [{ url: exceptionDefaultData[1], level: 'error' }],
    },
    {
      exception: exceptionMessage,
      category: 'promise',
      data: [{ url: 'smg.gb-next.ch', level: 'error' }],
    },
  ];

  return blockingUnhandledRejections.some((blockingItem) => {
    const matchException = event.exception?.values?.some((exception) => {
      return exception.mechanism?.type === 'onunhandledrejection' && exception.value?.includes(blockingItem.exception);
    });

    if (!matchException) {
      return false;
    }

    const matchData = blockingItem.data.filter(({ url, level }) => {
      return event.breadcrumbs?.some((breadcrumb) => {
        if (breadcrumb.category !== blockingItem.category) {
          return false;
        }

        if (blockingItem.category === 'promise') {
          return breadcrumb.data?.stack?.includes(url) && breadcrumb.level === level;
        }

        return breadcrumb.data?.url?.includes(url) && breadcrumb.level === level;
      });
    });

    return matchData.length === blockingItem.data.length;
  });
}

function hasBlockingMessages(_event: ErrorEvent, hint: EventHint): boolean {
  const blockingMessages = [
    // https://smg-re.atlassian.net/browse/RBD-1192
    'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
    // https://smg-re.atlassian.net/browse/RBD-1527
    "undefined is not an object (evaluating 'a.L')",
    // https://smg-re.atlassian.net/browse/KK-88
    'NS_ERROR_NOT_INITIALIZED: No error message',
  ];

  return blockingMessages.some((blockingItem) => (hint?.originalException as Error)?.message?.includes(blockingItem));
}

const ignoredEventKeywords = ['cookielaw', 'adnxs', 'user is blocked'];
function isIgnoredKeywordErrorEvent(event: Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  const isIgnoredEvent = frames?.some((frame) => {
    const stringifiedFrame = JSON.stringify(frame);
    return ignoredEventKeywords.some((ignoredEventKeyword) => stringifiedFrame.includes(ignoredEventKeyword));
  });

  return !!isIgnoredEvent;
}

export default function isIgnoredErrorEvent(event: ErrorEvent, hint: EventHint): boolean {
  if (isIgnoredKeywordErrorEvent(event)) {
    return true;
  }

  if (hasBlockingMessages(event, hint)) {
    return true;
  }

  if (hasBlockingTypeErrorByContext(event)) {
    return true;
  }

  if (hasBlockingUnhandledRejections(event)) {
    return true;
  }

  const blockingOthers = [
    {
      message: 'Failed to fetch',
      url: 'pagead2.googlesyndication.com',
    },
  ];

  return blockingOthers.some((blockingItem) => {
    return (
      (hint?.originalException as Error)?.message?.includes(blockingItem.message) &&
      event.breadcrumbs?.some(
        (breadcrumb) => breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes(blockingItem.url),
      )
    );
  });
}
