import { SupportedPlatforms } from '@smg-real-estate/domus';

const isPlatformIs24 = (): boolean => process.env.VUE_APP_PLATFORM === SupportedPlatforms.is24;

const isPlatformHg = (): boolean => process.env.VUE_APP_PLATFORM === SupportedPlatforms.hg;

const getCurrentPlatformTheme = (): SupportedPlatforms =>
  isPlatformIs24() ? SupportedPlatforms.is24 : SupportedPlatforms.hg;

export { isPlatformHg, isPlatformIs24, getCurrentPlatformTheme };
