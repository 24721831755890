import { StorageHelper } from '@smg-real-estate/hg-search-libs';

export enum CreatingSource {
  WEB = 'web',
  ANDROID_WEBVIEW = 'android_web_view',
  IOS_WEBVIEW = 'ios_web_view',
}

const STORAGE_NAMESPACE = 'tenant-plus';
const storage = new StorageHelper(STORAGE_NAMESPACE);

export const getCreatingSource = (): string => {
  return CreatingSource[getTheSource().toUpperCase() as keyof typeof CreatingSource] || CreatingSource.WEB;
};

export const getTheSource = (): string => {
  return storage.getLocalStorageItem('source') ?? '';
};

export const persistTheSource = (): void => {
  if (window.location.pathname.endsWith('/login-callback') || window.location.pathname.endsWith('/auth/login-callback'))
    return;

  const searchParams = new URLSearchParams(window.location.search);

  storage.setLocalStorageItem('source', searchParams.get('source') ?? '');
};
