<template>
  <div id="app">
    <HgLayout
      :baseUrl="baseUrl"
      :currentLanguage="currentLocale"
      :currentRoute="currentRoute"
      :getLanguageToLink="getLanguageSwitchLink"
      :hideMainNavigation="isMainNavigationHidden"
      @trackFooterClick="trackClickHandler"
      @trackHeaderClick="trackHeaderClickHandler"
    >
      <template #headerMetaAction>
        <HgMainNavItem
          :menuItem="$t('$hg.nav.harmonize.newListingMenuItem')"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          :bordered="true"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_INSERT_AD,
              eventLabel: EventLabel.TAB_NAVIGATION,
              contentLanguage: $i18n.locale,
            })
          "
        />
      </template>
      <template #headerMetaNavigation>
        <HgMainNavItem
          v-if="!isMainNavigationHidden"
          :menuItem="$t('$hg.nav.harmonize.favoriteMenuItem')"
          :icon="HeartOutlineIcon"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_FAVOURITES,
              eventLabel: EventLabel.TAB_NAVIGATION,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgMainNavItem
          :baseUrl="baseUrl"
          :menuItem="loggedInMenuItem"
          :accountsOrigin="accountsDomainOrigin"
          :icon="PersonOutlineIcon"
          :currentRoute="currentRoute"
          dropdownOrientation="right"
        />
      </template>
      <template #sideMenuMetaNavigation>
        <HgSideMenuNavItem
          v-if="!isMainNavigationHidden"
          :menuItem="$t('$hg.nav.harmonize.favoriteMenuItem')"
          :icon="HeartOutlineIcon"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_FAVOURITES,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          v-if="isPrivateUser"
          :menuItem="$t('$hg.nav.harmonize.overviewMenuItem')"
          :icon="PersonOutlineIcon"
          :currentRoute="currentRoute"
          :baseUrl="accountsDomainOrigin"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_ACCOUNT_OVERVIEW,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          v-if="isPrivateUser"
          :menuItem="$t('$hg.nav.harmonize.settingsMenuItem')"
          :icon="SettingsIcon"
          :currentRoute="currentRoute"
          :baseUrl="accountsDomainOrigin"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_ACCOUNT_SETTINGS,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          v-if="isPrivateUser"
          :menuItem="$t('$hg.nav.harmonize.myAdsMenuItem')"
          :icon="AppointmentIcon"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_MY_ADS,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          v-if="isPrivateUser"
          :menuItem="$t('$hg.nav.harmonize.searchAlertMenuItem')"
          :icon="BellIcon"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_SEARCH_ALERT,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          v-if="isPrivateUser"
          :menuItem="$t('$hg.nav.harmonize.messagesMenuItem')"
          :icon="MessengerIcon"
          :currentRoute="currentRoute"
          :baseUrl="baseUrl"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_MESSENGER,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
        <HgSideMenuNavItem
          :menuItem="$t('$hg.nav.harmonize.signOutMenuItem')"
          :icon="SignOutIcon"
          :currentRoute="currentRoute"
          :baseUrl="accountsDomainOrigin"
          @click.native="
            trackHeaderClickHandler({
              eventAction: InteractionGoal.OPEN_LOGOUT,
              eventLabel: EventLabel.SIDEBAR,
              contentLanguage: $i18n.locale,
            })
          "
        />
      </template>
      <div class="layout-container">
        <router-view />
      </div>
    </HgLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  HgLayout,
  HgMainNavItem,
  HgSideMenuNavItem,
  PersonOutlineIcon,
  MessengerIcon,
  EditIcon,
  BellIcon,
  AppointmentIcon,
  SignOutIcon,
  SettingsIcon,
  HeartOutlineIcon,
} from '@smg-real-estate/domus';
import layoutMixin from './layoutMixin';

export default defineComponent({
  name: 'HgLayoutDefault',
  components: {
    HgLayout,
    HgMainNavItem,
    HgSideMenuNavItem,
  },
  mixins: [layoutMixin],
  data() {
    return {
      baseUrl: build.config.HOMEGATE_BASE_URL,
      accountsDomainOrigin: build.config.HOMEGATE_ACCOUNTS_URL,
      PersonOutlineIcon,
      MessengerIcon,
      EditIcon,
      BellIcon,
      AppointmentIcon,
      SignOutIcon,
      SettingsIcon,
      HeartOutlineIcon,
    };
  },
  computed: {
    myAccountDropdown(): Record<string, any> {
      let privateItems = JSON.parse(JSON.stringify(this.$t('$hg.nav.privateItems')));

      /*
       * This checks 3 different cases:
       * 2. The href contains messenger || my-ads -> append it to test.homegate.ch or homegate.ch
       * 3. Otherwise, append it to accounts.test.homegate.ch or accounts.homegate.ch
       * */
      if (Array.isArray(privateItems)) {
        privateItems = privateItems.map((item) => {
          let modifiedHref;

          if (
            item.href.includes('messenger') ||
            item.href.includes('my-ads') ||
            item.trackingLabel.includes('searchAlertSettings')
          ) {
            modifiedHref = `${this.baseUrl}${item.href}`;
          } else {
            modifiedHref = `${this.accountsDomainOrigin}${item.href}`;
          }

          return { ...item, href: modifiedHref };
        });
      }

      return {
        label: this.$t('$hg.nav.loggedInMenuItem.label'),
        children: privateItems,
      };
    },
    loggedInMenuItem(): Object {
      return this.isPrivateUser ? this.myAccountDropdown : this.$t('$hg.nav.harmonize.signOutMenuItem');
    },
  },
});
</script>
