import * as Sentry from '@sentry/vue';

export enum AuthErrorType {
  GENERAL_ERROR = 'GeneralError',
  EMAIL_EXISTS_ERROR = 'EmailExistsError',
  NOT_AUTHORIZED_ERROR = 'NotAuthorizedError',
  ATTEMPTS_EXCEEDED_ERROR = 'AttemptsExceededError',
  CODE_EXPIRED_ERROR = 'CodeExpiredError',
  FORGOT_PASSWORD_GENERAL_ERROR = 'ForgotPasswordGeneralError',
  SET_PASSWORD_GENERAL_ERROR = 'SetPasswordGeneralError',
  WHITESPACE_IN_USERNAME_ERROR = 'WhitespaceInUsernameError',
  CONFIRM_EMAIL_GENERAL_ERROR = 'ConfirmEmailGeneralError',
  CONFIRM_EMAIL_CODE_EXPIRED_ERROR = 'ConfirmEmailCodeExpiredError',
}

export default class AuthError extends Error {
  type: AuthErrorType = AuthErrorType.GENERAL_ERROR;

  constructor(error, type?) {
    super(error.message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    // Auth0
    if (error.name === 'emailExists') {
      this.type = AuthErrorType.EMAIL_EXISTS_ERROR;
    } else if (error.name === 'wrongPassword') {
      this.type = AuthErrorType.NOT_AUTHORIZED_ERROR;
    } else if (type) {
      this.type = type;
      // If errors other than the above types thrown, then it should be sent to Sentry.
      Sentry.captureException(this, { tags: { errorType: this.type.toString() } });
    }
  }

  public static fromAxiosError(error) {
    if (error.response) {
      return new AuthError(error.response.data);
    }
    return new AuthError(error);
  }
}
