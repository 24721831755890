import { HgSupportedLanguage } from '@smg-real-estate/domus';
import { Route, NavigationGuardNext } from 'vue-router';
import { User, GroupType } from '@/auth/types';
import { DEFAULT_LANGUAGE } from '@/i18n';

const getLanguageSwitchLink = (route: Route, language: HgSupportedLanguage): Route => {
  return {
    name: route.name,
    query: route.query,
    hash: route.hash,
    params: {
      ...route.params,
      locale: language,
    },
  } as unknown as Route;
};

const getUserPreferredLanguages = (): Array<string> => {
  const userPreferredLanguages: string[] = [];
  if (!navigator.languages) {
    userPreferredLanguages.push(navigator.language);
  } else {
    userPreferredLanguages.push(...navigator.languages);
  }

  /* set removes duplicates, e.g if there are multiple versions of en language (["en-US", "en-UK"]), while split keeps only language shorthand without country initials. */
  return [...new Set(userPreferredLanguages.map((language) => language.split('-')[0]))];
};

/* checks does HG supported languages includes the provided language and returns boolean */
const isLanguageSupported = (language = '') => !!HgSupportedLanguage[language.toString().toUpperCase()];

const getFirstMatchingLanguage = (languages?: string[]) => {
  return (languages || getUserPreferredLanguages()).find((language) => isLanguageSupported(language));
};

const isAccessAllowed = (user: User, groups: GroupType[]): boolean =>
  groups.some((right) => !!user.groups?.includes(right));

const getLocale = (route: Route, defaultLanguage: HgSupportedLanguage): HgSupportedLanguage =>
  route.params.locale || route?.meta?.locale || defaultLanguage;

const rootRedirect = (): string => {
  const languages = getUserPreferredLanguages();
  const language = languages.some(isLanguageSupported) ? getFirstMatchingLanguage(languages) : DEFAULT_LANGUAGE;

  return `/${language}`;
};

const localeRedirect = (route: Route): Route => {
  return {
    name: route.name,
    query: route.query,
    hash: route.hash,
    meta: route.meta,
    params: {
      ...route.params,
      locale: getFirstMatchingLanguage() || DEFAULT_LANGUAGE,
    },
  } as unknown as Route;
};

const isProtectedRoute = (route: Route) => {
  return route.meta && (route.meta.authUser || route.meta.isPrivate);
};

const isUserAuthorizedForRoute = (user: User, route: Route) => {
  if (route.meta?.isPrivate) {
    return user.groups.includes(GroupType.PRIVATE);
  }
  return true;
};

const setupRouteMetaValues = (language: string, meta: any = {}): any => {
  return {
    ...meta,
    user: null,
    language,
  };
};

const updateRouteMetaValues = (user: User, meta: any = {}): any => {
  return {
    ...meta,
    user,
  };
};

const getHGOverviewSeekerUrlHref = (locale: string): string => {
  return `/${locale}/overview/seeker`;
};

const getHGOverviewListerUrlHref = (locale: string): string => {
  return `/${locale}/overview/lister`;
};

const getIS24OverviewSeekerUrlHref = (locale: string): string => {
  return `/${locale}/account-overview/seeker`;
};

const getIS24OverviewListerUrlHref = (locale: string): string => {
  return `/${locale}/account-overview/lister`;
};

const nextValidation = ({
  targetRoute,
  sourceRoute,
  targetLanguage,
}: {
  targetRoute: Route;
  sourceRoute: Route;
  targetLanguage: string;
}): {
  nextRoute: Route | boolean | string | null;
  nextLocale: string | null;
} => {
  // Only language has been switched
  if (
    targetRoute.params.locale &&
    sourceRoute.params.locale &&
    targetRoute.params.locale !== sourceRoute.params.locale
  ) {
    return {
      nextRoute: null,
      nextLocale: targetRoute.params.locale,
    };
  }
  // Empty path (root) check
  if (targetRoute.path === '/') {
    const nextRoute = rootRedirect();
    return {
      nextRoute,
      nextLocale: null,
    };
  }
  // Language check
  if (!isLanguageSupported(targetLanguage)) {
    const nextRoute = localeRedirect(targetRoute);
    return {
      nextRoute,
      nextLocale: nextRoute.params.locale,
    };
  }
  // Defaults
  return {
    nextRoute: null,
    nextLocale: targetLanguage,
  };
};

/* Verifies whether the current user is a business customer and if so, redirects the user to the Business Manager */
const redirectBusinessCustomers = (targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const user = (targetRoute.meta?.user as User) || null;

  if (user && isAccessAllowed(user, [GroupType.AGENCY])) {
    const baseUrl = build.config.HOMEGATE_BASE_URL;
    window.location.assign(`${baseUrl}/${targetRoute.params.locale}/business/`);
    return;
  }

  next();
};

export {
  getLanguageSwitchLink,
  getUserPreferredLanguages,
  getLocale,
  isLanguageSupported,
  getFirstMatchingLanguage,
  isAccessAllowed,
  isProtectedRoute,
  isUserAuthorizedForRoute,
  redirectBusinessCustomers,
  nextValidation,
  rootRedirect,
  localeRedirect,
  setupRouteMetaValues,
  updateRouteMetaValues,
  getHGOverviewListerUrlHref,
  getHGOverviewSeekerUrlHref,
  getIS24OverviewListerUrlHref,
  getIS24OverviewSeekerUrlHref,
};
