import { SubscriptionModel } from '@/helpers/types';

const subscriptionRequiredProperties = [
  'userId',
  'subscriptionState',
  'createdAt',
  'expiredAt',
  'paymentType',
  'accountEmailAddress',
  'metadata',
];

export function isSubscriptionModel(object: SubscriptionModel): boolean {
  if (!object) {
    return false;
  }

  return subscriptionRequiredProperties.every((requiredProperty) => Object.keys(object).includes(requiredProperty));
}
